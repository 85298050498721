<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox" v-if="configs && configs.length">
      <!-- <div class="officialWebsite" @click="jumpOfficialWeb"></div> -->
      <div class="telegram" @click="toContact(tgUrl)" v-if="tgUrl"></div>
      <div class="potato" @click="toContact(potatoUrl)" v-if="potatoUrl"></div>
      <div class="shangwu" @click="toContact(swUrl)" v-if="swUrl"></div>
      <div class="qudao" @click="toContact(qdUrl)" v-if="qdUrl"></div>
    </div>
    <div class="title"></div>
    <div class="girl"></div>
    <div class="downloadBox">
      <div class="installationTips">
        *安装提示:推荐使用UC浏览器扫描下载,下载好安装包以后,请断开网络*
      </div>
      <div class="bottomBox">
        <div class="qrcodeBox">
          <img class="qrcode" :src="qrImg" alt="" />
        </div>
        <div class="btnBox">
          <div class="btn android"></div>
          <div class="btn ios"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg", "configs"],
  watch: {
    configs(newValue) {
      newValue.forEach((item) => {
        if (item.configType == "tg_group") {
          this.tgUrl = item.link;
        } else if (item.configType == "potato_group") {
          this.potatoUrl = item.link;
        } else if (item.configType == "sw_cooperate") {
          this.swUrl = item.link;
        } else if (item.configType == "qd_cooperate") {
          this.qdUrl = item.link;
        }
      });
      return newValue;
    },
  },
  data() {
    return {
      tgUrl: "",
      potatoUrl: "",
      swUrl: "",
      qdUrl: "",
    };
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/AWJD1");
    },
    toPotato() {
      window.open("https://chptdl.org/pfgfpaopao");
    },
    toOfficialWeb() {
      window.open("https://erosm.co");
    },
    toQudao() {
      window.open("https://t.me/huansong_nangua");
    },
    toSw() {
      window.open("https://t.me/mu02guang");
    },
    toContact(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .logo {
    position: fixed;
    top: 54px;
    left: 81px;
    height: 102px;
    width: 302px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    top: 59px;
    right: 124.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 49px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin-right: 49px;
    }
    .officialWebsite {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/officialWebsite.png")
        no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin-right: 49px;
    }
    .qudao {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/qudao.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .shangwu {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/shangwu.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin-right: 49px;
    }
  }
  .title {
    position: absolute;
    top: 224px;
    left: 84px;
    width: 905px;
    height: 459px;
    background: url("./../../../assets/images/pc/title.webp") no-repeat;
    background-size: 100% 100%;
  }
  .girl {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 745px;
    height: 971px;
    background: url("./../../../assets/images/pc/girl.webp") no-repeat;
    background-size: 100% 100%;
  }
  .figure {
    height: 934px;
    width: 818px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .downloadBox {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    position: absolute;
    left: 84px;
    bottom: 62px;
    .installationTips {
      color: #f00;
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
    }
    .bottomBox {
      // margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 17px;
      display: flex;
      align-items: center;
      // justify-content: center;
      .qrcodeBox {
        width: 222px;
        height: 222px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 10px;
        .qrcode {
          width: 210px;
          height: 210px;
        }
      }
      .btnBox {
        margin-left: 18px;
        .btn {
          width: 398px;
          height: 102px;
        }
        .ios {
          background-image: url("../../../assets/images/pc/iosBtn.png");
          background-size: 100% 100%;
          margin-top: 11px;
        }
        .android {
          background-image: url("../../../assets/images/pc/androidBtn.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
